import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-unprocessed-accession-detail',
  templateUrl: './unprocessed-accession-detail.component.html',
  styleUrls: ['./unprocessed-accession-detail.component.scss']
})
export class UnprocessedAccessionDetailComponent implements OnInit {
  @Input() accessionId: string;
  constructor() { }

  ngOnInit(): void {
  }

}
