import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { ProcessedAccessionRoutingModule } from './processed-accession-routing.module';
import { ProcessedAccessionComponent } from './processed-accession.component';
import { ProcessedAccessionTableComponent } from './processed-accession-table/processed-accession-table.component';
import { ProcessedAccessionDetailComponent } from './processed-accession-detail/processed-accession-detail.component';


@NgModule({
  declarations: [ProcessedAccessionComponent, ProcessedAccessionTableComponent, ProcessedAccessionDetailComponent],
  imports: [
    CommonModule,
    ProcessedAccessionRoutingModule,
    SharedModule
  ],
  exports: [ProcessedAccessionComponent, ProcessedAccessionTableComponent
  ],
  providers: [
    DatePipe
  ]
})
export class ProcessedAccessionModule { }
