import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-processed-accession-detail',
  templateUrl: './processed-accession-detail.component.html',
  styleUrls: ['./processed-accession-detail.component.scss']
})
export class ProcessedAccessionDetailComponent implements OnInit {
  @Input() accessionId: string;
  constructor() { }

  ngOnInit(): void {
  }

}
