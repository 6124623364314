import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { ProcessedAccessionService } from '../processed-accession.service';
import { Accession, AccessionMessageType, AccessionTableModel, ProcessedAccessionTableDataSource } from './processed-accession-table-datasource';

@Component({
  selector: 'app-processed-accession-table',
  templateUrl: './processed-accession-table.component.html',
  styleUrls: ['./processed-accession-table.component.scss']
})
export class ProcessedAccessionTableComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Accession>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() isSearch: boolean;
  @Input() isWorkman: boolean;
  @Input() isProcessed: boolean = true;
  dashboardData: Array<Accession>;
  dataSource: ProcessedAccessionTableDataSource;
  accessionDataSource: Observable<Array<AccessionTableModel>>;
  resultsLength: number;
  filterForm: FormGroup;
  displayedColumns = ['accessionNumber', 'patientName', 'patientFamilyName', 'referringDoctorIdNumber', 'modailtyCode', 'examDate', 'facility', 'zipCode', 'authNumber', 'lastUpdatedDate', 'actions'];
  isLoadingResults: boolean;

  @ViewChild('donwloadCsvLink', { static: false }) private donwloadCsvLink: ElementRef;

  constructor(private processedAccessionService: ProcessedAccessionService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      'examStartDate': [],
      'examEndDate': [],
      'patientName': [],
      'accession': [],
      'accessionType': [],
      'messageType': [],
      'isProcessed': [this.isProcessed]
    });
    // this.getDashboardData();
    // this.dataSource = new ProcessedAccessionTableDataSource(this.processedAccessionService);
    // this.dataSource.loadDashboard();
    // this.filterForm.valueChanges.pipe(debounceTime(500)).subscribe(formResult => {
    //   this.loadDashboard();
    // });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.accessionDataSource = merge(this.sort.sortChange.pipe(tap(d => { this.paginator.pageIndex = 0; })), this.paginator.page, this.filterForm.valueChanges.pipe(debounceTime(500), tap(d => { this.paginator.pageIndex = 0 })))
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            return this.processedAccessionService.filterAccessionRecordsData(this.filterForm.value, this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
          }),
          map(data => {
            this.isLoadingResults = false;
            this.resultsLength = data.count;
            return data.data;
          }),
          catchError(() => {
            return observableOf([]);
          })
        );
    })
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(
    //     tap(() => this.loadDashboard())
    //   )
    //   .subscribe()
  }

  getDashboardData() {
    this.processedAccessionService.getDashboardData().subscribe(results => {
      this.dashboardData = results;
    });
  }

  loadDashboard() {
    this.dataSource.loadDashboard(
      this.filterForm?.controls['examStartDate'].value,
      this.filterForm?.controls['examEndDate'].value,
      this.filterForm?.controls['patientName'].value,
      this.filterForm?.controls['accession'].value,
      this.filterForm?.controls['accessionType'].value,
      this.filterForm?.controls['messageType'].value,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize);
  }
  searchForm() {
    this.loadDashboard();
  }
  resetForm() {
    this.filterForm.reset({ isProcessed: this.isProcessed });
    this.filterForm.updateValueAndValidity();
  }
  exportForm() {
    let csvData = [];
    this.processedAccessionService.exportDashboardResult(
      this.filterForm.value,
      this.sort.active,
      this.sort.direction,
      this.paginator.pageIndex,
      this.paginator.pageSize)
      .subscribe(result => {
        result.data.forEach(item => {
          var data = {
            accessionNumber: item.accessionNumber,
            patientName: item.patientName,
            patientFamilyName: item.patientFamilyName,
            referringDoctorIdNumber: item.referringDoctorIdNumber,
            modailtyCode: item.modailtyCode,
            examDate: item.examDate,
            facility: item.facility,
            zipCode: item.zipCode,
            authNumber: item.primaryAutnNo,
            //accessionType: item.accessionType
          };
          csvData.push(data);
        });
        const fileText = csvData.length > 0 ? this.convertToCSV(JSON.stringify(csvData)) : ['No records found'];
        const blob = new Blob([fileText], {
          type: 'text/csv'
        });
        const url = window.URL.createObjectURL(blob);
        const link = this.donwloadCsvLink.nativeElement;
        link.href = url;
        link.download = 'dashboard.csv';
        link.click();
        window.URL.revokeObjectURL(url);
      });
  }
  convertToCSV(jsonToConvert: string) {
    const jsonItems = JSON.parse(jsonToConvert);
    const items = jsonItems;
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(items[0]);
    let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    const normalizeHeaders: Array<string> = [];
    header.forEach(h => {
      normalizeHeaders.push(h.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }));
    });
    csv.unshift(normalizeHeaders.join(','));
    csv = csv.join('\r\n');
    return csv;
  }
  getAccessionType(type: AccessionMessageType) {
    return type == AccessionMessageType.ADT ? 'apply-green' : type == AccessionMessageType.ORU ? 'apply-yellow' : '';
  }
}
