import { Component, Inject, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { LookupModel, LookupType } from '../../models/lookup.model';
import { LookupService } from '../../services/lookup.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-lookup-table-dialog',
  templateUrl: './lookup-table-dialog.component.html',
  styleUrls: ['./lookup-table-dialog.component.scss']
})
export class LookupTableDialogComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  lookupDataSource: Observable<Array<LookupModel>>;
  displayedColumns: Array<string>;
  filterForm: FormGroup;
  isLoadingResults: boolean;
  resultsLength: number;
  dataSource: MatTableDataSource<any>;
  constructor(
    private lookupService: LookupService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<LookupTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.displayedColumns = ['id', 'name'];
    switch (this.data.lookupType) {
      case LookupType.Cpt:
        this.filterForm = this.fb.group({
          'name': [],
          'cptId': []
        });
        break;
      case LookupType.ExamStatus:
        this.filterForm = this.fb.group({
          'name': [],
          'examCode': []
        });
        break;
      case LookupType.Facility:
        this.filterForm = this.fb.group({
          'name': [],
          'facilityId': []
        });
        break;
      case LookupType.ICD10Code:
        this.filterForm = this.fb.group({
          'name': [],
          'icd10Code': []
        });
        break;
      case LookupType.Modality:
        this.filterForm = this.fb.group({
          'name': [],
          'modailityId': []
        });
        break;
      case LookupType.ReferringPhysicianNpi:
        this.filterForm = this.fb.group({
          'name': [],
          'referringPhysicianNpi': []
        });
        break;

      default:
        this.filterForm = this.fb.group({
          'name': [],
          'id': []
        });
        break;
    }
  }
  ngAfterViewInit(): void {
    // console.log(this.data);
    // this.dataSource = new MatTableDataSource();
    // this.lookupService.getLookupsByType({ lookupType: this.data.lookupType }, this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize)
    //   .subscribe(res => {
    //     this.dataSource.data = res.data;
    //     this.dataSource.sort = this.sort;
    //     this.dataSource.paginator = this.paginator;

    //   });
    setTimeout(() => {
    this.lookupDataSource = merge(this.sort.sortChange, this.paginator.page, this.filterForm.valueChanges.pipe(tap(() => {this.paginator.pageIndex = 0;})))
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.lookupService.getLookupsByType({ lookupType: this.data.lookupType, filter:this.filterForm.controls['name'].value },this.sort.active, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(data => {
          this.isLoadingResults = false;
          this.resultsLength = data.count;
          return data.data;
        }),
        catchError(() => {
          return of([]);
        }));
    })
  }
  returnSelectedLookup(lookup) {
    this.dialogRef.close(lookup);
  }
  setUpFilter() {
    this.filterForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe(
        formValue => {
          const filterValue: string = formValue['name'];
          this.dataSource.filter = filterValue.trim().toLowerCase();
        }
      )
  }

}
