import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { catchError, finalize, map } from 'rxjs/operators';
import { Observable, of as observableOf, merge, BehaviorSubject, of } from 'rxjs';
import { ProcessedAccessionService } from '../processed-accession.service';

export interface AccessionTableModel {
  accessionNumber: string;
  patientName: string;
  patientFamilyName: string;
  referringDoctorIdNumber: string;
  modailtyCode: string;
  examDate: Date;
  facility: string;
  zipCode: string;
  primaryAutnNo: string;
  lastUpdatedDate?: Date;
  accessionType: AccessionType;
  accessionMessageType: AccessionMessageType;
}
export interface PaginationModel<T> {
  data: Array<T>;
  count: number;
}
export interface Accession {
  id: string,
  accessionNumber: string,
  createdDate:Date,
  givenName: string,
  middleName:string,
  familyName: string,
  designation: string,
  streetAddress: string;
  city: string,
  state: string,
  country: string,
  zipCode: string,
  gender: string,
  phone: string,
  dateOfBirth: Date,
  accessionMessageType: AccessionMessageType,
  accessionType: AccessionType,
  comments: string,
  isProcessed: boolean,
  insurances: Array<Insurance>,
  exam: Exam
}

export interface Insurance {
  id: string;
  policyNumber?: string;
  createdDate:Date,
  groupNumber?: string;
  companyId?: string;
  payorAddress?: string;
  payorCity?: string;
  payorState?: string;
  payorZip?: string;
  payorName?: string;
  authNumber?: string;
  insuranceType: InsuranceType;
  accessionId: string;
}

export interface Exam {
  id: string,
  insuranceProviderType: string,
  createdDate:Date,
  referringPhysicianFirstName: string,
  referringPhysicianLastName: string,
  referringPhysicianNpi: string,
  examStatus: string,
  modailty: string,
  cpt: string,
  examDate: Date,
  facility: string,
  accidentDate: Date,
  icd10Code: string,
  accidentStatus: string,
  accidentState: string,
  accidentIndicator: string
}

export interface OverrideNote {
  accessionId: string,
  accession: Accession,
  comment: string,
}

export enum Gender {
  Male,
  Female
}
export enum AccessionMessageType {
  ADT,
  ORU,
  Both
}

export enum AccessionType {
  Standard,
  Workmans
}

export enum InsuranceType {
  Primary,
  Secondary,
  Teritiary
}

export enum ExamStatus {

}

// TODO: replace this with real data from your application


/**
 * Data source for the DashboardTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class ProcessedAccessionTableDataSource implements DataSource<Accession> {
  private processedAccessionSubject = new BehaviorSubject<Accession[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private processedAccessionService: ProcessedAccessionService) {
  }


  connect(collectionViewer: CollectionViewer): Observable<Accession[]> {
    return this.processedAccessionSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.processedAccessionSubject.complete();
    this.loadingSubject.complete();
  }

  loadDashboard(examStartDate?: Date, examEndDate?: Date, patientName?: string, accession?: string, accessionType?: number, messageType?: number, sortBy = '',
    sortDirection = 'asc', pageIndex = 0, pageSize = 50) {

    this.loadingSubject.next(true);

    this.processedAccessionService.findFilterData(examStartDate, examEndDate, patientName, accession, accessionType, messageType, sortBy, sortDirection,
      pageIndex, pageSize).pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(result => this.processedAccessionSubject.next(result));
  }

}
