import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkmansRoutingModule } from './workmans-routing.module';
import { WorkmansTableComponent } from './workmans-table/workmans-table.component';
import { WorkmansDetailComponent } from './workmans-detail/workmans-detail.component';
import { WorkmansComponent } from './workmans.component';
import { SharedModule } from '../shared/shared.module';
import { ProcessedAccessionModule } from '../processed-accession/processed-accession.module';


@NgModule({
  declarations: [WorkmansTableComponent, WorkmansDetailComponent, WorkmansComponent],
  imports: [
    CommonModule,
    WorkmansRoutingModule,
    SharedModule,
    ProcessedAccessionModule
  ]
})
export class WorkmansModule { }
