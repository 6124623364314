import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-workmans-detail',
  templateUrl: './workmans-detail.component.html',
  styleUrls: ['./workmans-detail.component.scss']
})
export class WorkmansDetailComponent implements OnInit {
  @Input() accessionId: string;
  constructor() { }

  ngOnInit(): void {
  }

}
