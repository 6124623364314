import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnprocessedAccessionDetailComponent } from './unprocessed-accession-detail/unprocessed-accession-detail.component';
import { UnprocessedAccessionComponent } from './unprocessed-accession.component';

const routes: Routes = [
  { path: '', component: UnprocessedAccessionComponent, pathMatch: 'full' },
  { path: 'edit/:accessionId', component: UnprocessedAccessionDetailComponent }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UnprocessedAccessionRoutingModule { }


