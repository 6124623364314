import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Accession, AccessionMessageType, AccessionType, Insurance, InsuranceType } from '../../../processed-accession/processed-accession-table/processed-accession-table-datasource';
import { AccessionService } from '../../services/accession.service';
import { MatDialog } from '@angular/material/dialog';
import { OverrideDialogComponent } from '../override-dialog/override-dialog.component';
import { LookupModel, LookupType } from '../../models/lookup.model';
import { LookupService } from '../../services/lookup.service';
import { LookupTableDialogComponent } from '../lookup-table-dialog/lookup-table-dialog.component';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-accession-form-details',
  templateUrl: './accession-form-details.component.html',
  styleUrls: ['./accession-form-details.component.scss']
})
export class AccessionFormDetailsComponent implements OnInit {
  @Input() accessionId: string;
  @Input() isWorkman: boolean;
  isViewOnly: boolean;

  accessionData: any;//Dashboard;
  errorMessages: any[] = [];
  accessionMessageType: AccessionMessageType;
  insuranceDetails: FormArray;
  examDetailsFormGroup: FormGroup;
  accessionForm: FormGroup;
  facilities: any[];
  refPhysicianFirstNames: any[];
  refPhysicianLastNames: any[];
  refPhysicianNpis: any[];
  examStatuses: any[];
  modalities: any[];
  icd10Codes: any[];
  cpts: any[];
  modalityLookupValue: LookupModel;
  facilityLookupValue: LookupModel;

  //lookups: Array<LookupModel>;

  selectedPhysician;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accessionService: AccessionService,
    private location: Location,
    private dialog: MatDialog,
    private lookupService: LookupService) { }

  ngOnInit(): void {
    // this.lookupService.getLookups()
    //   .subscribe(lookups => {
    //     this.lookups = lookups;
    //   });
    this.insuranceDetails = this.fb.array([]);
    this.isViewOnly = !this.router.url.includes('edit');
    if (this.accessionId) {
      this.getAccessionData();

    } else {
      this.route.params
        .subscribe(_params => {
          this.accessionId = _params['accessionId'];
          if (this.accessionId) {
            this.getAccessionData();
          }
          else {
            this.accessionData = {
              'id': null,
              'accessionNo': null
            };
            this.setupFormData();
          }
        });
    }
  }
  getAccessionData() {
    this.accessionService.getAccessionDetails(this.accessionId)
      .subscribe(accession => {
        //let primaryInsuranceDetails: Insurance;
        //let secondaryInsuranceDetails: Insurance;
        //accession.insuranceDetails.forEach(item => {
        //  switch (item.insuranceType) {
        //    case InsuranceType.Primary:
        //      primaryInsuranceDetails = item;
        //      break;
        //    case InsuranceType.Secondary:
        //      secondaryInsuranceDetails = item;
        //      break;
        //  }
        //});
        this.isViewOnly = accession.isProcessed;
        this.accessionMessageType = accession.accessionMessageType;

        this.isWorkman = accession.accessionType == AccessionType.Workmans;
        if (!accession.exam) {
          accession.exam = {
            id: '',
            insuranceProviderType: '',
            referringPhysicianFirstName: '',
            referringPhysicianLastName: '',
            referringPhysicianNpi: '',
            examStatus: '',
            modailty: '',
            cpt: '',
            examDate: null,
            facility: '',
            accidentDate: null,
            icd10Code: '',
            accidentStatus: '',
            accidentState: '',
            accidentIndicator: '',
            createdDate: new Date()
          };
        }
        this.accessionData = {
          'id': accession.id,
          'accessionNumber': accession.accessionNumber,
          'givenName': accession.givenName,
          'familyName': accession.familyName,
          'middleName': accession.middleName,
          'designation': accession.designation,
          'streetAddress': accession.streetAddress,
          'createdDate': accession.createdDate,
          'city': accession.city,
          'state': accession.state,
          'country': accession.country,
          'zipCode': accession.zipCode,
          'gender': accession.gender,
          'phone': accession.phone,
          'accessionType': accession.accessionType,
          'dateOfBirth': accession.dateOfBirth,
          'insuranceProviderType': accession.exam.insuranceProviderType,
          'referringPhysicianFirstName': accession.exam.referringPhysicianFirstName,
          'referringPhysicianLastName': accession.exam.referringPhysicianLastName,
          'referringPhysicianNpi': accession.exam.referringPhysicianNpi,
          'examStatus': accession.exam.examStatus,
          'modailty': accession.exam.modailty,
          'cpt': accession.exam.cpt,
          'examDate': accession.exam.examDate,
          'examCreatedDate': accession.exam.createdDate,
          'facility': accession.exam.facility,
          'accidentDate': accession.exam.accidentDate,
          'accidentState': accession.exam.accidentState,
          'accidentIndicator': accession.exam.accidentIndicator,
          'accidentStatus': accession.exam.accidentStatus,
          'icd10Code': accession.exam.icd10Code,
          'insurances': this.insuranceDetails,
          'comments': accession.comments,
          'accessionMessageType': accession.accessionMessageType,
          'examId': accession.exam.id,
          'isProcessed': accession.isProcessed
        };
        accession.insurances.forEach(item => {
          this.addItem(item);
        });
        this.setupFormData();
        this.accessionForm.get('exam.insuranceProviderType').valueChanges.pipe(debounceTime(500))
          .subscribe(val => {
            this.checkAuthNumberValidation();
          })
      });
  }
  addItem(item: any) {
    const insuranceItem = this.buildInsuranceGroup(item);
    if (!this.insuranceDetails) {
      this.insuranceDetails = this.fb.array([insuranceItem]);
    } else {
      this.insuranceDetails.push(insuranceItem);
    };
  }
  buildInsuranceGroup(item?: Insurance) {
    let group: FormGroup;
    let isRequired = false;
    if (item.insuranceType == InsuranceType.Primary) {
      if (item.payorName.toLowerCase().includes('bcbs' || 'blue payors') && this.accessionData.accessionMessageType == AccessionMessageType.ADT) {
        isRequired = true;
      } else {
        isRequired = false;
      }
    } else {

    }
    group = this.fb.group({
      'id': [item.id],
      'policyNumber': [item.policyNumber],
      'groupNumber': [item.groupNumber, this.accessionMessageType != 1 && isRequired ? [Validators.required, this.noWhitespaceValidator] : null],
      'companyId': [item.companyId],
      'payorAddress': [item.payorAddress],
      'payorCity': [item.payorCity],
      'payorState': [item.payorState],
      'payorZip': [item.payorZip],
      'payorName': [item.payorName],
      'authNumber': [item.authNumber],
      'insuranceType': InsuranceType[item.insuranceType],
      'accessionId': [item.accessionId],
      'createdDate': [item.createdDate]
    });

    if (item.insuranceType == InsuranceType.Primary) {
      group.controls['payorName']
        .valueChanges
        .pipe(debounceTime(500))
        .subscribe((result: string) => {
          if ((result.replace(" ", "").toLowerCase().includes('bluepayors') || result.toLowerCase().includes('bcbs')) && this.accessionData.accessionMessageType == AccessionMessageType.ADT) {
            group.controls['groupNumber'].setValidators([Validators.required, this.noWhitespaceValidator]);
            group.controls['groupNumber'].updateValueAndValidity();
          } else {
            group.controls['groupNumber'].clearValidators();
            group.controls['groupNumber'].updateValueAndValidity();
          }
        });
      // group.valueChanges.pipe(debounceTime(500)).subscribe(result => {
      //   if(result){
      //   if(this.accessionMessageType != 0){
      //     group.controls['authNumber'].setValidators([Validators.required]);
      //     group.controls['authNumber'].updateValueAndValidity();
      //   }
      //   if(this.accessionMessageType != 1){

      //   }
      // }
      // });
    }
    return group;
  }
  setupFormData() {
    this.accessionForm = this.fb.group({
      'id': [this.accessionData.id],
      'createdDate': [this.accessionData.createdDate],
      'accessionNumber': [this.accessionData.accessionNumber],
      'givenName': [this.accessionData.givenName, [Validators.required, this.noWhitespaceValidator]],//^[a-zA-Z \-\']+  /[a-zA-Z0-9^ ]/   ^[a-zA-Z ]*$
      'familyName': [this.accessionData.familyName, [Validators.required, this.noWhitespaceValidator]],
      'middleName': [this.accessionData.middleName],
      'designation': [this.accessionData.designation],
      'streetAddress': [this.accessionData.streetAddress, [Validators.required, this.noWhitespaceValidator]],
      'city': [this.accessionData.city],
      'state': [this.accessionData.state],
      'country': [this.accessionData.country],
      'zipCode': [this.accessionData.zipCode, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5)]],
      'gender': [this.accessionData.gender, [Validators.required]],
      'phone': [this.accessionData.phone],
      'dateOfBirth': [this.accessionData.dateOfBirth, Validators.required],
      'accessionType': [this.accessionData.accessionType],
      'accessionMessageType': [this.accessionData.accessionMessageType],
      'exam': this.fb.group({
        'id': [this.accessionData.examId],
        'insuranceProviderType': [this.accessionData.insuranceProviderType],
        'referringPhysicianFirstName': [this.accessionData.referringPhysicianFirstName, this.accessionMessageType != 0 ? Validators.required : ''],
        'referringPhysicianLastName': [this.accessionData.referringPhysicianLastName, this.accessionMessageType != 0 ? Validators.required : ''],
        'referringPhysicianNpi': [this.accessionData.referringPhysicianNpi, this.accessionMessageType != 0 ? Validators.required : ''],
        'examStatus': [this.accessionData.examStatus],
        'modailty': [this.accessionData.modailty],
        'cpt': [this.accessionData.cpt],
        'examDate': [this.accessionData.examDate],
        'facility': [this.accessionData.facility, Validators.required],
        'accidentDate': [this.accessionData.accidentDate, this.isWorkman && this.accessionMessageType != 1 ? Validators.required : ''],
        'icd10Code': [this.accessionData.icd10Code, this.accessionMessageType != 1 ? Validators.required : null],
        'accidentState': [this.accessionData.accidentState, this.isWorkman && this.accessionMessageType != 1 ? Validators.required : ''],
        'accidentIndicator': [this.accessionData.accidentIndicator, this.isWorkman && this.accessionMessageType != 1 ? Validators.required : ''],
        'accidentStatus': [this.accessionData.accidentStatus, this.isWorkman && this.accessionMessageType != 1 ? Validators.required : ''],
        'employerName': [this.accessionData.employerName, this.isWorkman && this.accessionMessageType != 1 ? Validators.required : ''],
        'accessionId': [this.accessionData.id],
        'createdDate': [this.accessionData.examCreatedDate]
      }),
      'insurances': this.insuranceDetails,
      'comments': [this.accessionData.comments],
      'isProcessed': [this.accessionData.isProcessed]
    });
    this.accessionForm.updateValueAndValidity();
    this.accessionForm.markAllAsTouched();

  }
  getInsuranceType(index: number) {
    return this.insuranceDetails.controls[index].get('insuranceType').value;
  }
  readyToSent() {
    if (this.accessionForm.valid) {
      this.accessionForm.controls['isProcessed'].setValue(true);
      this.accessionService.updateAccession(this.accessionId, this.accessionForm.value)
        .subscribe(res => {
          this.location.back();
        },
          (error) => {
          }
        );
    }
  }
  backToList() {
    this.location.back();
  }
  openOverRideDialog() {
    const overrideDialogRef = this.dialog.open(OverrideDialogComponent, {
      width: '800px',
      height: '700px',
      disableClose: true
    });
    overrideDialogRef.afterClosed()
      .subscribe(dialogData => {
        if (dialogData) {
          this.accessionForm.controls['isProcessed'].setValue(true);
          const formValue = { accessionId: this.accessionId, comment: dialogData.overrideNotes, accession: this.accessionForm.value }
          this.accessionService.overrideNotes(this.accessionId, formValue)
            .subscribe(res => {
              this.location.back();
            },
              (error) => {
              }
            );
        }
      },
        (error) => {
        });
  }
  openLookupDialog(lookupType: number) {
    var dialodRef = this.dialog.open(LookupTableDialogComponent, {
      minWidth: '500px',
      height: '600px',
      data: { lookupType: lookupType }
    });
    dialodRef.afterClosed()
      .subscribe(lookupValue => {
        if (lookupValue) {
          switch (lookupType) {
            case 0:
              this.accessionForm.get('exam.modailty').setValue(lookupValue.displayValue);
              this.modalityLookupValue = lookupValue;
              if (this.accessionMessageType != 0)
                this.checkAuthNumberValidation();
              break;
            case 1:
              this.accessionForm.get('exam.facility').setValue(lookupValue.displayValue);
              this.facilityLookupValue = lookupValue;
              if (this.accessionMessageType == 2)
                this.checkAuthNumberValidation();
              break;
            case 2:
              this.accessionForm.get('exam.icd10Code').setValue(lookupValue.displayValue);
              break;
            case 3:
              this.accessionForm.get('exam.referringPhysicianFirstName').setValue(lookupValue.displayValue.split(' ')[0]);
              this.accessionForm.get('exam.referringPhysicianLastName').setValue(lookupValue.displayValue.split(' ')[1]);
              this.accessionForm.get('exam.referringPhysicianNpi').setValue(lookupValue.storedValue);
              break;
            case 4:
              this.accessionForm.get('exam.cpt').setValue(lookupValue.displayValue);
              break;
            case 5:
              this.accessionForm.get('exam.examStatus').setValue(lookupValue.displayValue);
              break;
            default:
              break;
          }
        }
      })
  }
  checkAuthNumberValidation() {
    let groupItems: any = (this.accessionForm.controls["insurances"] as FormArray).controls;
    const storedValue = this.modalityLookupValue?.storedValue.toUpperCase();
    const facility = this.facilityLookupValue?.storedValue.toUpperCase();
    const insuranceProviderType = this.accessionForm.get('exam.insuranceProviderType').value.toUpperCase();
    if (storedValue == "MR" || storedValue == "CT" || storedValue == "NM" || storedValue == "PET" || facility == "SME" || insuranceProviderType == "SELF") {
      for (let item of groupItems) {
        if (item.value.insuranceType == InsuranceType[InsuranceType.Primary]) {
          item.controls['authNumber'].setValidators([Validators.required, this.noWhitespaceValidator]);
          item.controls['authNumber'].updateValueAndValidity();
        }
      }
    }
    else {
      for (let item of groupItems) {
        if (item.value.insuranceType == InsuranceType[InsuranceType.Primary]) {
          item.controls['authNumber'].clearValidators();
          item.controls['authNumber'].updateValueAndValidity();
        }
      }
    }
  }

  fetchErrors(form: FormGroup | FormArray) {
    this.errorMessages = [];
    return this.getErrorMessages(form);
  }
  getErrorMessages(form: FormGroup | FormArray, index?: number) {

    let errorMessages: any = [];
    const result = Object.keys(form.controls).forEach(key => {
      let controlErrors: ValidationErrors;
      let control: AbstractControl = form.get(key);
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.getErrorMessages(control, parseInt(key.toString()))
      } else {
        controlErrors = control.errors;

      }
      let msgs = this.checkErrorMessages(key, controlErrors, index);
      if (msgs.length)
        this.errorMessages.push(msgs)
    });
    return this.errorMessages;
  }
  checkErrorMessages(key, controlErrors, index?) {
    let errorMessages: any = [];
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach(keyError => {
        let keyName = key.replace(/([a-z])([A-Z])/g, '$1 $2');
        keyName = keyName.charAt(0).toUpperCase() +
          keyName.substr(1);
        if (keyError == 'required')
          errorMessages.push(' ' + (!isNaN(index) ? this.getInsuranceType(index) + " " : '') + keyName + " is " + keyError);
        if (keyError == 'minlength')
          errorMessages.push(' ' + keyName + " must be at least 5 characters long");
        else if (keyError == 'pattern' || keyError == 'matDatepickerParse' || keyError == 'whitespace')
          errorMessages.push(" Please enter valid " + keyName);
      });
    }
    return errorMessages;
  }
  getErrorMessage(dateControl: AbstractControl, name: string) {
    let keyName = name.replace(/([a-z])([A-Z])/g, '$1 $2');
    keyName = keyName.charAt(0).toUpperCase() +
      keyName.substr(1);
    if (dateControl.errors?.required || dateControl.errors?.matDatepickerParse) {
      return 'Please enter valid ' + keyName;
    }
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
}
