import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { UnprocessedAccessionModule } from './unprocessed-accession/unprocessed-accession.module';
import { ProcessedAccessionModule } from './processed-accession/processed-accession.module';
import { NavBarComponent } from './shared/components/nav-bar/nav-bar.component';
import { WorkmansModule } from './workmans/workmans.module';


import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel, ProtocolMode } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.adfs.clientId,
      authority: environment.adfs.authority,
      redirectUri: environment.adfs.redirectUri,
      postLogoutRedirectUri: environment.adfs.postLogoutRedirectUri,
      navigateToLoginRequestUrl: true,
      protocolMode: ProtocolMode.OIDC,

    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: true, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback: () => { },
        piiLoggingEnabled: false
      }
    }

  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  protectedResourceMap.set(environment.businessQcApiEndpoint, []);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: []
    },
    loginFailedRoute: '/login-failed'
  };
}
// }

let bootStrapComponents: any[] = [
  AppComponent
];
let imports: any[] = [
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  AppRoutingModule,
  SharedModule,
  UnprocessedAccessionModule,
  ProcessedAccessionModule,
  BrowserAnimationsModule,
  WorkmansModule
];
let providers: any[] = [];
if (!environment.local) {
  bootStrapComponents =
    [
      AppComponent
    ];
  imports = [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    UnprocessedAccessionModule,
    ProcessedAccessionModule,
    BrowserAnimationsModule,
    WorkmansModule
  ];
  providers = [];
} else {
  bootStrapComponents = [
    AppComponent,
    MsalRedirectComponent
  ];
  imports = [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MsalModule,
    SharedModule,
    UnprocessedAccessionModule,
    ProcessedAccessionModule,
    BrowserAnimationsModule,
    WorkmansModule
  ];
  providers = [
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
      },
      {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
      },
      MsalService,
      MsalGuard,
      MsalBroadcastService
    ]
  ];
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavBarComponent
  ],
  imports: [
    ...imports
  ],
  providers: [
    ...providers
  ],
  bootstrap: [...bootStrapComponents]

})
export class AppModule { }
