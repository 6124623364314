import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LookupModel, LookupType } from '../models/lookup.model';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  baseUrl = environment.businessQcApiEndpoint;
  constructor(private http: HttpClient) { }

  getLookups(): Observable<Array<LookupModel>> {
    return this.http.get<Array<LookupModel>>(`${this.baseUrl}/api/lookups`);
  }
  getLookupsByType(filters: any, sortBy = '', sortOrder = 'asc', pageNumber = 0, pageSize = 50): Observable<LookupTypeTableModel> {
    
    let params = new HttpParams()
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('filter', filters['filter'] ? filters['filter'] : '')
      .set('lookupType', filters['lookupType'] || filters['lookupType'] === 0 ? filters['lookupType'].toString() : '');


    return this.http.get<LookupTypeTableModel>(`${this.baseUrl}/api/lookups/filter`, { params: params });
  }
}
export interface LookupTypeTableModel {
  count: number;
  data: Array<LookupModel>;
}
