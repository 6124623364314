import { Pipe, PipeTransform } from '@angular/core';
import { LookupModel, LookupType } from '../models/lookup.model';

@Pipe({
  name: 'lookupFilter'
})
export class LookupFilterPipe implements PipeTransform {

  transform(items: LookupModel[], filter: LookupType): any {
    if (!items || !filter) {
        return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => item.lookupType === filter);
}

}
