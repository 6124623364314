import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Accession, OverrideNote } from '../../processed-accession/processed-accession-table/processed-accession-table-datasource';

@Injectable({
  providedIn: 'root'
})
export class AccessionService {
  baseUrl = environment.businessQcApiEndpoint;

  constructor(private http: HttpClient) { }

  getAccessionDetails(accessionDetailId: string): Observable<Accession> {
    return this.http.get<Accession>(`${this.baseUrl}/api/accessions/${accessionDetailId}`);
  }
  updateAccession(id: string, data: Accession): Observable<Accession> {
    return this.http.put<Accession>(`${this.baseUrl}/api/accessions/${id}`, data);
  }
  overrideNotes(id: string, data: OverrideNote): Observable<Accession> {
    return this.http.put<Accession>(`${this.baseUrl}/api/accessions/${id}/override`, data);
  }
}
