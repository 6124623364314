export interface LookupModel {
  storedValue: string;
  displayValue: string;
  lookupType: LookupType;
}
export enum LookupType {
  Modality,
  Facility,
  ICD10Code,
  ReferringPhysicianNpi,
  ReferringPhysicianFirstName,
  ReferringPhysicianLastName,
  Cpt,
  ExamStatus
}
