import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'accessions/unprocessed', loadChildren: () => import(`./unprocessed-accession/unprocessed-accession.module`).then(m => m.UnprocessedAccessionModule) },
  { path: 'accessions/processed', loadChildren: () => import(`./processed-accession/processed-accession.module`).then(m => m.ProcessedAccessionModule) },
  { path: 'accessions/workmans', loadChildren: () => import(`./workmans/workmans.module`).then(m => m.WorkmansModule) },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

export const routedComponents = [
  AppComponent
];

