import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessionFormDetailsComponent } from '../shared/components/accession-form-details/accession-form-details.component';
import { ProcessedAccessionDetailComponent } from './processed-accession-detail/processed-accession-detail.component';
import { ProcessedAccessionComponent } from './processed-accession.component';

const routes: Routes = [
  { path: '', component: ProcessedAccessionComponent, pathMatch: 'full' },
  { path: 'view/:accessionId', component: ProcessedAccessionDetailComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProcessedAccessionRoutingModule { }
