import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Accession, AccessionMessageType, AccessionTableModel, AccessionType, PaginationModel } from './processed-accession-table/processed-accession-table-datasource';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ProcessedAccessionService {
  baseUrl = environment.businessQcApiEndpoint;
  constructor(private http: HttpClient, private datePipe: DatePipe) { }
  
  getDashboardData(): Observable<Array<Accession>> {
    return this.http.get<Array<Accession>>(`${this.baseUrl}/api/accessions`);
    //return of(this.data);
  }
  filterAccessionRecordsData(filters: any, sortBy = '', sortOrder = 'asc',
    pageNumber = 0, pageSize = 50): Observable<PaginationModel<AccessionTableModel>> {
    let params: HttpParams;
    let isProcessed;
    let startDate = this.formatDate(filters['examStartDate']);
    let endDate = this.formatDate(filters['examEndDate']);
    if (filters['isProcessed'] != undefined || filters['isProcessed'] != null) {
      isProcessed = filters['isProcessed'];
    }
    else {
      isProcessed = null;
    }
    params = new HttpParams()
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('isProcessed', isProcessed)
      .set('examStartDate',  startDate)
      .set('examEndDate',  endDate)
      .set('patientName', filters['patientName'] ? filters['patientName'] : '')
      .set('accessionNumber', filters['accession'] ? filters['accession'] : '')
      .set('accessionType', AccessionType[filters['accessionType']] ? filters['accessionType']?.toString() : '')
      .set('accessionMessageType', AccessionMessageType[filters['messageType']] ? filters['messageType']?.toString() : '')

    return this.http.get<PaginationModel<AccessionTableModel>>(`${this.baseUrl}/api/accessions/filter`, { params });
  }
  findFilterData(examStartDate: Date, examEndDate: Date, patientName: string, accession: string, accessionType: number, messageType: number, sortBy = '', sortOrder = 'asc',
    pageNumber = 0, pageSize = 50): Observable<Array<Accession>> {
    let params: HttpParams;

    // let startDate = this.formatDate(filters['examStartDate']);
    // let endDate = this.formatDate(filters['examEndDate']);
    params = new HttpParams()
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('examStartDate', examStartDate ? new Date(examStartDate).toDateString() : '')
      .set('examEndDate', examEndDate ? new Date(examEndDate).toDateString() : '')
      .set('patientName', patientName ? patientName : '')
      .set('accessionNumber', accession ? accession : '')
      .set('accessionType', AccessionType[accessionType] ? accessionType?.toString() : '')
      .set('accessionMessageType', AccessionMessageType[messageType] ? messageType?.toString() : '')

    //return of(this.data);
    return this.http.get<Array<Accession>>(`${this.baseUrl}/api/accessions/filter`, { params });
  }
  exportDashboardResult(filters: any, sortBy = '', sortOrder = 'asc',
    pageNumber = 0, pageSize = 50): Observable<PaginationModel<AccessionTableModel>> {
    let params: HttpParams;
    let isProcessed;
    let startDate = this.formatDate(filters['examStartDate']);
    let endDate = this.formatDate(filters['examEndDate']);
    if (filters['isProcessed'] != undefined || filters['isProcessed'] != null) {
      isProcessed = filters['isProcessed'];
    }
    else {
      isProcessed = null;
    }
    params = new HttpParams()
      .set('sortBy', sortBy)
      .set('sortOrder', sortOrder)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('isProcessed', isProcessed)
      .set('examStartDate', startDate)
      .set('examEndDate', endDate)
      .set('patientName', filters['patientName'] ? filters['patientName'] : '')
      .set('accessionNumber', filters['accession'] ? filters['accession'] : '')
      .set('accessionType', AccessionType[filters['accessionType']] ? filters['accessionType']?.toString() : '')
      .set('accessionMessageType', AccessionMessageType[filters['messageType']] ? filters['messageType']?.toString() : '')
    //return of(this.data);
    return this.http.get<PaginationModel<AccessionTableModel>>(`${this.baseUrl}/api/accessions/export`, { params });
  }
  private formatDate(date: string) {
    if(date){
    return this.datePipe.transform(date,'yyyy-MM-dd');
    } else{
      return '';
    }
  }
}
