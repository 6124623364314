import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { UnprocessedAccessionRoutingModule } from './unprocessed-accession-routing.module';
import { UnprocessedAccessionComponent } from './unprocessed-accession.component';
import { UnprocessedAccessionTableComponent } from './unprocessed-accession-table/unprocessed-accession-table.component';
import { ProcessedAccessionModule } from '../processed-accession/processed-accession.module';
import { UnprocessedAccessionDetailComponent } from './unprocessed-accession-detail/unprocessed-accession-detail.component';



@NgModule({
  declarations: [UnprocessedAccessionComponent, UnprocessedAccessionTableComponent, UnprocessedAccessionDetailComponent],
  imports: [
    CommonModule,
    UnprocessedAccessionRoutingModule,
    SharedModule,
    ProcessedAccessionModule
  ]
})
export class UnprocessedAccessionModule { }
