import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-processed-accession',
  templateUrl: './processed-accession.component.html',
  styleUrls: ['./processed-accession.component.scss']
})
export class ProcessedAccessionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
