import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unprocessed-accession',
  templateUrl: './unprocessed-accession.component.html',
  styleUrls: ['./unprocessed-accession.component.scss']
})
export class UnprocessedAccessionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
