
import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  loginDisplay = true;

  // constructor(private authService: MsalService, private msalBroadcastService: MsalBroadcastService) { }

  // ngOnInit(): void {
  //   this.msalBroadcastService.msalSubject$
  //     .pipe(
  //       filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
  //     )
  //     .subscribe((result: EventMessage) => {
  //       console.log(result);
  //       const payload = result.payload as AuthenticationResult;
  //       this.authService.instance.setActiveAccount(payload.account);
  //     });

  //   this.msalBroadcastService.inProgress$
  //     .pipe(
  //       filter((status: InteractionStatus) => status === InteractionStatus.None)
  //     )
  //     .subscribe(() => {
  //       this.setLoginDisplay();
  //     })

  // }

  // setLoginDisplay() {
  //   this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  // }
}
