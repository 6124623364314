import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  sideNavOpen: boolean;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.sideNavOpen = false;
    this.router.events?.subscribe((routerEvent) => {

      if (routerEvent instanceof NavigationEnd) {
        this.sideNavOpen = false;
      }
    });
  }
  togglenav() {
    this.sideNavOpen = !this.sideNavOpen;
  }
}
