import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkmansDetailComponent } from './workmans-detail/workmans-detail.component';
import { WorkmansComponent } from './workmans.component';

const routes: Routes = [
  { path: '', component: WorkmansComponent, pathMatch: 'full' },
  { path: 'edit/:accessionId', component: WorkmansDetailComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkmansRoutingModule { }
